import React from 'react';
import { Form, Button, Card, CardDeck, Row, Col, Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import SignupForm from '../signupFormClient'
import InfoBoxes from '../infoBoxes'
import TechnologyGrid from '../technologyGrid'

const Client = () => {
    return (
        <div className="App">
            <div className="headerFill">
                <div class="bgOne">
                    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="backgroundDarkClient">
                        {/* <Navbar.Brand href="#home"> */}
                            {/* <img src="yarnlogo2.png" height="70px" /> */}
                        {/* </Navbar.Brand> */}
                        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
                        <Navbar id="responsive-navbar-nav">
                            <Nav className="mr-auto">
                                {/* <div id="element1"></div> */}
                                {/* <img src="rslogo1.png" width="30%" height="10%" /> */}
                                <div className="element1"></div>
                            </Nav>
                            <Nav>
                                {/* <Nav.Link className="active" href="/">Find Developers</Nav.Link> */}
                                <Nav.Link href="/developers">For Developers</Nav.Link>
                            </Nav>
                        </Navbar>
                    </Navbar>
                    <Row className="row-2">
                        <Col sm="12" md="12" className="bgGrey">
                            <Row>
                                <Col sm={{ span: 10, offset: 1}} md={{ span: 8, offset: 2 }} lg={{ span: 4, offset: 4 }} className="headLeft">
                                    <div class="headLeftInner">
                                        {/* re<span>stack</span> */}
                                        <div class="rectangle"></div>
                                        <div class="rectangle"></div>
                                        <div class="rectangle"></div>
                                    </div>
                                </Col>
                                {/* <Col md="6">
                                    <InfoBoxes displaytext="Demo text"/>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row></div>
                <div class="bgOne"></div>
                {/* <div class="bgOne"></div> */}
            </div>
            <header className="App-header">
                <Container fluid="true">
                    <div className="contentFill">
                        <Row className="boldThree">
                            <Col lg="4" md="12" sm="12">
                                <div class="innerThrree">
                                    <h2><strong>Flexible </strong>Timings</h2>
                                    <p>Find reliable, temporary development resources quickly, without the long-term commitments</p>
                                    <img src="icon-clock.png" />
                                </div>
                            </Col>
                            <Col lg="4" md="12" sm="12">
                                <h2><strong>Skilled </strong>Developers</h2>
                                <p>Bring in vetted &amp; supported freelance devs with the programming language skills that you need</p>
                                <img src="icon-keyboard.png" />
                            </Col>
                            <Col lg="4" md="12" sm="12">
                                <h2><strong>Simple </strong>Onboarding</h2>
                                <p>Dictate in advance how you want developers to work on your projects and with your team</p>
                                <img src="icon-puzzle.png" />
                            </Col>
                        </Row>
                        <Row className="detailsRow">
                            <Col md={{ span: 8, offset: 2}}>
                                <h3>Our Mission</h3>
                                <p>No disrupting, no gimmicks, no bullshit.</p>
                                <p>We aren't trying to disrupt, revolutionise or radically change how you work. From experience, we are tired of unreliable outsourcing and expensive gimmicky recruiters. RESTACK exists to add flexibility to your team, so that you can take on more projects, grow faster and have the time you need to hit deadlines &amp; recruit permanent staff.</p>
                                <Row>
                                    <Col md={{ span: 6 }} className="box">
                                        <h4>Technically Minded</h4>
                                        <p>We create technically driven relationships; providing temporary access to reliable, vetted developers on a flexible basis</p>
                                    </Col>
                                    <Col md={{ span: 6, offset: 0 }} className="box">
                                        <h4>Extend Your Team</h4>
                                        <p>Developers that don't just take your task and complete it, but integrate into your team quickly and effectively, as and when you need them.</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="techRow">
                            <Col>
                                <TechnologyGrid />
                            </Col>
                        </Row>
                        <Row className="whiteSignup">
                            <Col className="interiorSignup" sm={{ span: 10, offset:1 }} md={{ span: 8, offset: 2 }}>
                            <h2>Let's talk</h2>
                                <input type="text" placeholder="Email" />
                                <p>This doesn't subscribe you to anything. We'll only use your email to get in touch soon about your needs</p>
                                {/* <Row> */}
                                    {/* <Col md="5">
                                        <div className="alignMid">
                                            <div className="formContainer">
                                                <p class="formText">Stay ahead. Sign up for beta access.</p>
                                                <br /><br />
                                                <Form id="form1" action="https://gmail.us20.list-manage.com/subscribe/post?u=f4af0d3153dc2ddcaa36e1238&amp;id=92ccab4ce2">
                                                    <Form.Group controlId="earlyAccess">
                                                        <Form.Control name="EMAIL" id="mc-EMAIL" type="email" placeholder="Email" className="center-text" required  />
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                            <p className="thankYouText">Thank you for signing up. We'll be in touch soon</p>
                                        </div>
                                    </Col>
                                    <Col md="7">
                                        <div class="bg"></div>
                                        <div class="bg bg2"></div>
                                        <div class="bg bg3"></div>
                                        <div class="bg bg4"></div>
                                        <div class="bg bg5"></div>
                                    </Col> */}
                                {/* </Row> */}
                            </Col>
                        </Row>
                        {/* <Row className="towerRow">
                            <Col sm="12" md="12" className="bgGrey">
                                <Row>
                                    <Col md="6">
                                        <div class="scene">
                                            <div class="shape tower-main main-tower-8">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p><i class="devicon-typescript-plain"></i></p></div>
                                            </div>
                                            <div class="shape tower-main main-tower-7">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p><i class="devicon-typescript-plain"></i></p></div>
                                            </div>
                                            <div class="shape tower-main main-tower-6">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p><i class="devicon-trello-plain"></i></p></div>
                                            </div>
                                            <div class="shape tower-main main-tower-5">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p><i class="devicon-typescript-plain"></i></p></div>
                                            </div>
                                            <div class="shape tower-main main-tower-4">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p><i class="devicon-trello-plain"></i></p></div>
                                            </div>
                                            <div class="shape tower-main main-tower-3">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p><i class="devicon-typescript-plain"></i></p></div>
                                            </div>
                                            <div class="shape tower-main main-tower-2">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p><i class="devicon-trello-plain"></i></p></div>
                                            </div>
                                            <div class="shape tower-main main-tower-1">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p><i class="devicon-typescript-plain"></i></p></div>
                                            </div>
                                            <div class="shape tower-main main-tower0">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p><i class="devicon-trello-plain"></i></p></div>
                                            </div>
                                            <div class="shape tower-main main-tower1">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p><i class="devicon-typescript-plain"></i></p></div>
                                            </div>
                                            <div class="shape tower-main main-tower2">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p><i class="devicon-trello-plain"></i></p></div>
                                            </div>
                                            <div class="shape tower-main main-tower3">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p><i class="devicon-typescript-plain"></i></p></div>
                                            </div>
                                            <div class="shape tower-main main-tower4">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p><i class="devicon-trello-plain"></i></p></div>
                                            </div>
                                            <div class="shape tower-main main-tower-enter">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p><i class="devicon-vuejs-plain-wordmark"></i></p></div>
                                            </div>
                                            <div class="shape tower-main main-tower5">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p><i class="devicon-wordpress-plain"></i></p></div>
                                            </div>
                                            <div class="shape tower-main main-tower6">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p><i class="devicon-mongodb-plain-wordmark"></i></p></div>
                                            </div>
                                            <div class="shape tower-main main-tower7">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                            <div class="face tp"><p>O</p></div>
                                            </div>
                                                <div class="shape tower-main main-tower8">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                            <div class="face tp"><p>O</p></div>
                                            </div>
                                                <div class="shape tower-main main-tower9">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                            <div class="face tp"><p>O</p></div>
                                            </div>
                                            <div class="shape tower-main main-tower10">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p>O</p></div>
                                            </div>
                                            <div class="shape tower-main main-tower11">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p>O</p></div>
                                            </div>
                                            <div class="shape tower-main main-tower12">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p>O</p></div>
                                            </div>
                                            <div class="shape tower-main main-tower13">
                                                <div class="face ft"></div>
                                                <div class="face bk"></div>
                                                <div class="face rt"></div>
                                                <div class="face lt"></div>
                                                <div class="face tp"><p>O</p></div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <InfoBoxes displaytext="Demo text"/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row> */}
                        {/* <Row className="cardRow cardRowClient">
                            <Col md={{ span: 10, offset: 1 }}>
                                <Row>
                                    <CardDeck>
                                        <Col md="6" sm="6" lg="3">
                                            <Card bg="dark" text="white">
                                                <Card.Img variant="top" src="icons/64px_bulb-63.svg" />
                                                <Card.Body>
                                                    <Card.Title>Development Resources</Card.Title>
                                                    <Card.Text>
                                                    Find developers that are experts with the programming languages that you need in your team
                                                    </Card.Text>
                                                </Card.Body>
                                                <Card.Footer>
                                                    <a href="#"><small className="text-muted">Learn More</small></a>
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                        <Col md="6" sm="6" lg="3">
                                            <Card bg="dark" text="white">
                                                <Card.Img variant="top" src="icons/64px_three-dimensional-object.svg" />
                                                <Card.Body>
                                                    <Card.Title>Risk Free Hiring</Card.Title>
                                                    <Card.Text>
                                                    Don't spend time searching, interviewing, testing and onbboarding random freelancers, simply tell us what you need to happen
                                                    </Card.Text>
                                                </Card.Body>
                                                <Card.Footer>
                                                    <a href="#"><small className="text-muted">Learn More</small></a>
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                        <Col md="6" sm="6" lg="3">
                                            <Card bg="dark" text="white">
                                                <Card.Img variant="top" src="icons/64px_clock.svg" />
                                                <Card.Body>
                                                    <Card.Title>Flexible Timings</Card.Title>
                                                    <Card.Text>
                                                    Find the right development resources quickly, when you need them without the long-term commitments
                                                    </Card.Text>
                                                </Card.Body>
                                                <Card.Footer>
                                                    <a href="#"><small className="text-muted">Learn More</small></a>
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                        <Col md="6" sm="6" lg="3">
                                            <Card bg="dark" text="white">
                                                <Card.Img variant="top" src="icons/64px_handshake.svg" />
                                                <Card.Body>
                                                    <Card.Title>Simplified Onboarding</Card.Title>
                                                    <Card.Text>
                                                    Dictate how you want developers to work within your team and they will be given all the information and access they need in advance
                                                    </Card.Text>
                                                </Card.Body>
                                                <Card.Footer>
                                                    <a href="#"><small className="text-muted">Learn More</small></a>
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                    </CardDeck>
                                </Row>
                            </Col>
                        </Row> */}
                        {/* <Row className="bannerRow">
                            <Col md={{ span: 8, offset: 2 }}>
                                <h2>Are You a Developer?</h2>
                                <p>We are always looking for more developers to join reStack's library of available professionals. But it's not easy to get on reStack's approved list. We only work with the best and brightest.</p>
                                <a href="#">Find out More >></a>
                            </Col>
                        </Row> */}
                        {/* <Row className="signupContainer">
                            <Row className="signupRow signupRowClient">
                                <Col md={{ span: 5, offset: 1 }}>
                                    <p>Enter your email address and be among the first to gain access to reStack's library of developers via the reStack early access program.</p>
                                    <p class="disclaimer">Your email address will never be shared or spammed.</p>
                                </Col>
                                <Col md={{ span: 3, offset: 1 }}>
                                    <Form id="form2" action="https://gmail.us20.list-manage.com/subscribe/post?u=f4af0d3153dc2ddcaa36e1238&amp;id=92ccab4ce2">
                                        <Form.Group controlId="earlyAccess">
                                            <Form.Control name="EMAIL" id="mc-EMAIL" type="email" placeholder="Email" className="center-text" required  />
                                        </Form.Group>
                                        <Button variant="primary" type="submit">
                                            Let me know
                                        </Button>
                                    </Form>
                                </Col>
                            </Row>
                        </Row> */}
                        <Row className="footerRow">
                            {/* <Col md={{ span: 2, offset: 1 }}>
                                &copy; RESATACK 2019
                            </Col>
                            <Col md={{ span: 3, offset: 5 }}>
                                <a href="#">FIND DEVELOPERS</a>
                                <a href="#">FOR DEVELOPERS</a>
                            </Col> */}
                            <Col md={{ span: 4, offset: 4 }}>
                                <a href="#">FIND DEVELOPERS</a>
                                <a href="#">FOR DEVELOPERS</a>
                                <br /><br />
                                &copy; RESATACK 2019
                            </Col>
                            {/* <Col md={{ span: 2, offset: 3 }}>
                                <ul>
                                    <li>Item One</li>
                                    <li>Item Two</li>
                                    <li>Item Three</li>
                                </ul>
                            </Col>
                            <Col md="2">
                                <ul>
                                    <li>Item One</li>
                                    <li>Item Two</li>
                                    <li>Item Three</li>
                                </ul>
                            </Col>
                            <Col md="2">
                                <ul>
                                    <li>Item One</li>
                                    <li>Item Two</li>
                                    <li>Item Three</li>
                                </ul>
                            </Col> */}
                        </Row>
                    </div>
                </Container>
            </header>
        </div>
    )
}

export default Client;