import React from 'react';
import { Form, Button, Card, CardDeck, Row, Col, Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import SignupForm from '../signupFormClient'
import InfoBoxes from '../infoBoxes'

const ClientInfo = () => {
    return (
        <div className="App">
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="backgroundDarkClient">
                <Navbar.Brand href="#home"><img src="../yarnlogo2.png" height="70px" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto"></Nav>
                    <Nav>
                        <Nav.Link className="active" href="/">Find Developers</Nav.Link>
                        <Nav.Link href="/developers">For Developers</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <header className="App-header">
                <Container fluid="true">
                    <Row>
                        <Col sm="12" md="7" className="bgGrey">
                            <Container>
                                <Col md={{ span: 11, offset: 1 }}>
                                    <div class="infoBox">
                                        <h1>
                                            <div class="titlecontainer">
                                                <div class="titlestring">
                                                    <div class="titleinner">
                                                        Development Resources
                                                    </div>
                                                </div>
                                            </div>
                                            YARN: Modern Development Resourcing
                                        </h1>
                                        <nav aria-label="breadcrumb">
                                            <ol class="breadcrumb">
                                                <li class="breadcrumb-item"><a href="/">Teams</a></li>
                                                <li class="breadcrumb-item active" aria-current="page">Development Resources</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </Col>
                            </Container>
                        </Col>
                        <Col sm="12" md="5" className="d-flex align-items-center justify-content-md-center bgGrey insideTemplate">
                            <Row className="fixedMid">
                                <div className="alignMid">
                                    <div className="formContainer">
                                        <p class="formText">Sign up for early access</p>
                                        <Form id="form1" action="https://gmail.us20.list-manage.com/subscribe/post?u=f4af0d3153dc2ddcaa36e1238&amp;id=92ccab4ce2">
                                            <Form.Group controlId="earlyAccess">
                                                <Form.Control name="EMAIL" id="mc-EMAIL" type="email" placeholder="Email" className="center-text" required  />
                                            </Form.Group>
                                            <Button variant="primary" type="submit" className="devButton">
                                                Let me know
                                            </Button>
                                        </Form>
                                    </div>
                                    <p className="thankYouText">Thank you for signing up. We'll be in touch soon</p>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        {/* <div class="jumbotron jumbotron-fluid">
                            <div class="container">
                                <h1 class="display-4">Fluid jumbotron</h1>
                                <p class="lead">This is a modified jumbotron that occupies the entire horizontal space of its parent.</p>
                            </div>
                        </div> */}
                    </Row>
                    {/* <Row className="cardRow cardRowClient">
                        <Col md={{ span: 10, offset: 1 }}>
                            <Row>
                                <CardDeck>
                                    <Col md="6" sm="6" lg="3">
                                        <Card bg="dark" text="white">
                                            <Card.Img variant="top" src="/icons/64px_bulb-63.svg" />
                                            <Card.Body>
                                                <Card.Title>Development Resources</Card.Title>
                                                <Card.Text>
                                                Find developers that are experts with the programming languages that you need in your team
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Footer>
                                                <a href="#"><small className="text-muted">Learn More</small></a>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                    <Col md="6" sm="6" lg="3">
                                        <Card bg="dark" text="white">
                                            <Card.Img variant="top" src="/icons/64px_three-dimensional-object.svg" />
                                            <Card.Body>
                                                <Card.Title>Risk Free Hiring</Card.Title>
                                                <Card.Text>
                                                Don't spend time searching, interviewing, testing and onbboarding new developers, simply tell us what you need to happen
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Footer>
                                                <a href="#"><small className="text-muted">Learn More</small></a>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                    <Col md="6" sm="6" lg="3">
                                        <Card bg="dark" text="white">
                                            <Card.Img variant="top" src="/icons/64px_clock.svg" />
                                            <Card.Body>
                                                <Card.Title>Flexible Timings</Card.Title>
                                                <Card.Text>
                                                Find the right development resources quickly, when you need them without the long-term committments
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Footer>
                                                <a href="#"><small className="text-muted">Learn More</small></a>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                    <Col md="6" sm="6" lg="3">
                                        <Card bg="dark" text="white">
                                            <Card.Img variant="top" src="/icons/64px_handshake.svg" />
                                            <Card.Body>
                                                <Card.Title>Simplified Onboarding</Card.Title>
                                                <Card.Text>
                                                Dictate how you want developers to work within your team and they will be given all the information and access they need in advance
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Footer>
                                                <a href="#"><small className="text-muted">Learn More</small></a>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                </CardDeck>
                            </Row>
                        </Col>
                    </Row> */}
                    <Row className="bannerRow">
                        <Col md={{ span: 8, offset: 2 }}>
                            <h2>Are You a Developer?</h2>
                            <p>We are always looking for more developers to join Yarns library of available professionals. But it's not easy to get on Yarns approved list. We only work with the best and brightest.</p>
                            <a href="#">Find out More >></a>
                        </Col>
                    </Row>
                    <Row className="signupContainer">
                        <Row className="signupRow signupRowClient">
                            <Col md={{ span: 5, offset: 1 }}>
                                <p>Enter your email address and be among the first to gain access to Yarns library of developers via the Yarn early access program.</p>
                                <p class="disclaimer">Your email address will never be shared or spammed.</p>
                            </Col>
                            <Col md={{ span: 3, offset: 1 }}>
                                <Form id="form2" action="https://gmail.us20.list-manage.com/subscribe/post?u=f4af0d3153dc2ddcaa36e1238&amp;id=92ccab4ce2">
                                    <Form.Group controlId="earlyAccess">
                                        <Form.Control name="EMAIL" id="mc-EMAIL" type="email" placeholder="Email" className="center-text" required  />
                                    </Form.Group>
                                    <Button variant="primary" type="submit">
                                        Let me know
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Row>
                    <Row className="footerRow">
                        <Col md={{ span: 2, offset: 3 }}>
                            <ul>
                                <li>Item One</li>
                                <li>Item Two</li>
                                <li>Item Three</li>
                            </ul>
                        </Col>
                        <Col md="2">
                            <ul>
                                <li>Item One</li>
                                <li>Item Two</li>
                                <li>Item Three</li>
                            </ul>
                        </Col>
                        <Col md="2">
                            <ul>
                                <li>Item One</li>
                                <li>Item Two</li>
                                <li>Item Three</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </header>
        </div>
    )
}

export default ClientInfo;