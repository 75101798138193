import React, {Component} from 'react';
export default class technologyGrid extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const element = (
            <main class="gallery">
                <ul class="gallery__list">
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-amazonwebservices-original"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-amazonwebservices-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-angularjs-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-angularjs-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-mysql-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-mysql-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-nginx-original"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-nginx-original"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-zend-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-zend-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-nodejs-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-nodejs-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-mongodb-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-mongodb-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-yii-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-yii-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-cplusplus-line"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-cplusplus-plain"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-django-line"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-django-plain"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-backbonejs-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-backbonejs-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-bootstrap-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-bootstrap-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-c-line"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-c-plain"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-java-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-java-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-go-line"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-go-plain"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-csharp-line"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-csharp-plain"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-oracle-original"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-oracle-original"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-php-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-php-plain"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-css3-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-css3-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-drupal-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-drupal-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-slack-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-slack-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-grunt-line"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-grunt-line-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-postgresql-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-postgresql-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-dot-net-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-dot-net-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-python-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-python-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-rails-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-rails-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-vuejs-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-vuejs-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-laravel-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-laravel-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-wordpress-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-wordpress-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-github-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-github-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-react-original"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-react-original-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-docker-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-docker-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-ruby-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-ruby-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-git-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-git-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-krakenjs-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-krakenjs-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-trello-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-trello-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-redis-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-redis-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-sass-original"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-sass-original"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-express-original"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-express-original"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-html5-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-html5-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-swift-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-swift-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-typescript-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-typescript-plain"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-jquery-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-jquery-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-symfony-original"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-symfony-original-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-erlang-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-erlang-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-less-plain-wordmark"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-less-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-gulp-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-gulp-plain"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-codeigniter-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-codeigniter-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-foundation-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-foundation-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-ubuntu-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-ubuntu-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-mocha-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-mocha-plain"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-jasmine-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-jasmine-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-appcelerator-original"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-appcelerator-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-android-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-android-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-bower-line"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-bower-line-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <i class="devicon-confluence-plain"></i>
                                    </div>
                                    <div class="flip-card-back">
                                        <i class="devicon-confluence-plain-wordmark"></i>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </li>
                </ul>
            </main>
        )
        return (<div className="techgallery">
            {element}
        </div>)
    }
}