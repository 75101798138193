import React from 'react';
import { Form, Button, Card, CardDeck, Row, Col, Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import SignupForm from '../signupFormDev'
import InfoBoxes from '../infoBoxes'

const Dev = () => {
    return (
        <div className="App">
            <Navbar collapseOnSelect expand="lg" bg="darkBlue" variant="dark" className="backgroundDarkDev">
                <Navbar.Brand href="#home"><img src="yarnlogo2.png" height="70px" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto"></Nav>
                    <Nav>
                        <Nav.Link href="/">Find Developers</Nav.Link>
                        <Nav.Link className="active" href="/developers">For Developers</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <header className="App-header">
                <Container fluid="true">
                    <Row>
                        <Col sm="12" md="7" className="bgGrey">
                            <Container>
                                <Col md={{ span: 11, offset: 1 }}>
                            {/* <Col> */}
                                    <InfoBoxes displaytext="Demo text"/>
                                    </Col>
                            </Container>
                        </Col>
                        <Col sm="12" md="5" className="d-flex align-items-center justify-content-md-center bgGrey">
                            <Row className="fixedMid">
                                <SignupForm type='<Button variant="primary" type="submit" className="devButton">Let me know</Button>'/>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="cardRow cardRowDev">
                        <Col md={{ span: 10, offset: 1 }}>
                            <Row>
                                <CardDeck>
                                    <Col md="6" sm="6" lg="3">
                                        <Card bg="dark" text="white">
                                            <Card.Img variant="top" src="icons/64px_ranking.svg" />
                                            <Card.Body>
                                                <Card.Title>Let the Work Come to You</Card.Title>
                                                <Card.Text>
                                                Don't worry about finding jobs, let us bring serious, realistic, vetted projects to you
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Footer>
                                                <a href="#"><small className="text-muted card-footer-dev">Learn More</small></a>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                    <Col md="6" sm="6" lg="3">
                                        <Card bg="dark" text="white">
                                            <Card.Img variant="top" src="icons/64px_basket-simple-add.svg" />
                                            <Card.Body>
                                                <Card.Title>Free and Simple</Card.Title>
                                                <Card.Text>
                                                No cost or fee to sign up, just some questions to ensure that you are among the best developers out there.
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Footer>
                                                <a href="#"><small className="text-muted card-footer-dev">Learn More</small></a>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                    <Col md="6" sm="6" lg="3">
                                        <Card bg="dark" text="white">
                                            <Card.Img variant="top" src="icons/64px_safe.svg" />
                                            <Card.Body>
                                                <Card.Title>No Risk</Card.Title>
                                                <Card.Text>
                                                You don't have to worry about contracts, payments, scope creep or public profiles and negative reviews
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Footer>
                                                <a href="#"><small className="text-muted card-footer-dev">Learn More</small></a>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                    <Col md="6" sm="6" lg="3">
                                        <Card bg="dark" text="white">
                                            <Card.Img variant="top" src="icons/64px_hammer.svg" />
                                            <Card.Body>
                                                <Card.Title>No Busywork</Card.Title>
                                                <Card.Text>
                                                No need to worry about public profiles or reviews, once you're approved, there are no public profiles to worry about
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Footer>
                                                <a href="#"><small className="text-muted card-footer-dev">Learn More</small></a>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                </CardDeck>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="bannerRow">
                        <Col md={{ span: 8, offset: 2 }}>
                            <h2>Need a Developer?</h2>
                            <p>If you have or are a part of a team that needs quick, reliable access to skilled developers, head over to the teams section of the site and get in touch</p>
                            <a href="#">Find out More >></a>
                        </Col>
                    </Row>
                    <Row className="signupContainer">
                        <Row className="signupRow signupRowDev">
                            <Col md={{ span: 5, offset: 1 }}>
                                <p>Enter your email address and be among the first to gain access to Yarns library of developers via the Yarn early access program.</p>
                                <p class="disclaimer">Your email address will never be shared or spammed.</p>
                            </Col>
                            <Col md={{ span: 3, offset: 1 }}>
                                <Form id="form2" action="https://gmail.us20.list-manage.com/subscribe/post?u=f4af0d3153dc2ddcaa36e1238&amp;id=92ccab4ce2">
                                    <Form.Group controlId="earlyAccess">
                                        <Form.Control name="EMAIL" id="mc-EMAIL" type="email" placeholder="Email" className="center-text" required  />
                                    </Form.Group>
                                    <Button variant="primary" type="submit">
                                        Let me know
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Row>
                    <Row className="footerRow">
                        <Col md={{ span: 2, offset: 3 }}>
                            <ul>
                                <li>Item One</li>
                                <li>Item Two</li>
                                <li>Item Three</li>
                            </ul>
                        </Col>
                        <Col md="2">
                            <ul>
                                <li>Item One</li>
                                <li>Item Two</li>
                                <li>Item Three</li>
                            </ul>
                        </Col>
                        <Col md="2">
                            <ul>
                                <li>Item One</li>
                                <li>Item Two</li>
                                <li>Item Three</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </header>
        </div>
    )
}

export default Dev;