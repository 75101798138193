import React, {Component} from 'react';
import { Button, Form } from 'react-bootstrap';
export default class signupForm extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const element = (
            <div className="alignMid">
                <div className="formContainer">
                    <p class="formText">Sign up to join Yarns<br /> early access program</p>
                    <Form id="form1" action="https://gmail.us20.list-manage.com/subscribe/post?u=f4af0d3153dc2ddcaa36e1238&amp;id=92ccab4ce2">
                        <Form.Group controlId="earlyAccess">
                            {/* <Form.Label>Email address</Form.Label> */}
                            <Form.Control name="EMAIL" id="mc-EMAIL" type="email" placeholder="Email" className="center-text" required  />
                            {/* <Form.Text className="text-muted">
                                Get notified on BETA launch
                            </Form.Text> */}
                        </Form.Group>
                        {/* <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" />
                        </Form.Group> */}
                        {/* <Form.Group controlId="formBasicChecbox">
                            <Form.Check type="checkbox" label="Check me out" />
                        </Form.Group> */}
                        <Button variant="primary" type="submit" className="devButton">
                            Let me know
                        </Button>
                    </Form>
                </div>
                <p className="thankYouText">Thank you for signing up. We'll be in touch soon</p>
            </div>
        )
        return (<div className="comptext">
            {element}
        </div>)
    }
}