import React, { Component } from 'react';
import { Form, Button, Card, CardDeck, Row, Col, Container } from 'react-bootstrap';
// import { BrowserRouter as Router } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import './App.css';
// import SignupForm from './signupForm'
// import InfoBoxes from './infoBoxes'
import ClientComponent from './components/team/clientComponent'
import InfoComponent from './components/team/infoComponent'
import HiringComponent from './components/team/hiringComponent'
import TimingsComponent from './components/team/timingsComponent'
import OnboardingComponent from './components/team/onboardingComponent'

import DevComponent from './components/dev/devComponent'
import WorkComponent from './components/dev/workComponent'
import FreeComponent from './components/dev/freeComponent'
import RiskComponent from './components/dev/riskComponent'
import BusyworkComponent from './components/dev/busyworkComponent'

import {
	BrowserRouter as Router,
	Route,
	Link,
	Switch,
	Redirect
} from 'react-router-dom'

class App extends Component {
	render() {
		return (
			<Router>
				<div className="App">
					<Switch>
						<Route exact path="/" component={ClientComponent} />
						<Route path="/teams/development-resources" component={InfoComponent} />
						<Route path="/teams/risk-free-hiring" component={RiskComponent} />
						<Route path="/teams/flexible-timings" component={TimingsComponent} />
						<Route path="/teams/simplified-onboardding" component={OnboardingComponent} />

						<Route path="/developers" component={DevComponent} />
						<Route path="/developers/easily-find-work" component={WorkComponent} />
						<Route path="/developers/free-and-simple" component={FreeComponent} />
						<Route path="/developers/no-risk" component={RiskComponent} />
						<Route path="/developers/no-busywork" component={BusyworkComponent} />
					</Switch>
				</div>
			</Router>
			// <div className="App">
			// 	<nav class="navbar navbar-expand-md navbar-dark backgroundDark">
			// 		<div class="navbar-collapse collapse w-100 order-1 dual-collapse2">
			// 			<ul class="navbar-nav mr-auto">
			// 				<li class="nav-item">
			// 					<a class="nav-link" href="#"><img src="yarnlogo2.png" height="70px" /></a>
			// 				</li>
			// 			</ul>
			// 		</div>
			// 		<div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
			// 			<ul class="navbar-nav ml-auto">
			// 				<li class="nav-item">
			// 					<a class="nav-link" href="#">Find Developers</a>
			// 				</li>
			// 				<li class="nav-item">
			// 					<a class="nav-link" href="#">For Developers</a>
			// 				</li>
			// 			</ul>
			// 		</div>
			// 	</nav>
			// 	<header className="App-header">
			// 		<Container fluid="true">
			// 			<Row>
			// 				<Col sm="12" md="7" className="bgGrey">
			// 					<Container>
			// 						<Col md={{ span: 11, offset: 1 }}>
			// 					{/* <Col> */}
			// 							<InfoBoxes displaytext="Demo text"/>
			// 							</Col>
			// 					</Container>
			// 				</Col>
			// 				<Col sm="12" md="5" className="d-flex align-items-center justify-content-md-center bgGrey">
			// 					<Row className="fixedMid">
			// 						<SignupForm displaytext="Sign Up Form"/>
			// 					</Row>
			// 				</Col>
			// 			</Row>
			// 			<Row className="cardRow">
			// 				<Col md={{ span: 10, offset: 1 }}>
			// 					<Row>
			// 						<CardDeck>
			// 							<Col md="6" sm="6" lg="3">
			// 								<Card bg="dark" text="white">
			// 									<Card.Img variant="top" src="icons/64px_bulb-63.svg" />
			// 									<Card.Body>
			// 										<Card.Title>Development Resources</Card.Title>
			// 										<Card.Text>
			// 										Find developers that are experts with the programming languages that you need in your team
			// 										</Card.Text>
			// 									</Card.Body>
			// 									<Card.Footer>
			// 										<small className="text-muted">Learn More</small>
			// 									</Card.Footer>
			// 								</Card>
			// 							</Col>
			// 							<Col md="6" sm="6" lg="3">
			// 								<Card bg="dark" text="white">
			// 									<Card.Img variant="top" src="icons/64px_three-dimensional-object.svg" />
			// 									<Card.Body>
			// 										<Card.Title>Risk Free Hiring</Card.Title>
			// 										<Card.Text>
			// 										Don't spend time searching, interviewing, testing and onbboarding new developers, simply tell us what you need to happen
			// 										</Card.Text>
			// 									</Card.Body>
			// 									<Card.Footer>
			// 										<small className="text-muted">Learn More</small>
			// 									</Card.Footer>
			// 								</Card>
			// 							</Col>
			// 							<Col md="6" sm="6" lg="3">
			// 								<Card bg="dark" text="white">
			// 									<Card.Img variant="top" src="icons/64px_clock.svg" />
			// 									<Card.Body>
			// 										<Card.Title>Flexible Timings</Card.Title>
			// 										<Card.Text>
			// 										Find the right development resources quickly, when you need them without the long-term committments
			// 										</Card.Text>
			// 									</Card.Body>
			// 									<Card.Footer>
			// 										<small className="text-muted">Learn More</small>
			// 									</Card.Footer>
			// 								</Card>
			// 							</Col>
			// 							<Col md="6" sm="6" lg="3">
			// 								<Card bg="dark" text="white">
			// 									<Card.Img variant="top" src="icons/64px_handshake.svg" />
			// 									<Card.Body>
			// 										<Card.Title>Simplified Onboarding</Card.Title>
			// 										<Card.Text>
			// 										Dictate how you want developers to work within your team and they will be given all the information and access they need in advance
			// 										</Card.Text>
			// 									</Card.Body>
			// 									<Card.Footer>
			// 										<small className="text-muted">Learn More</small>
			// 									</Card.Footer>
			// 								</Card>
			// 							</Col>
			// 						</CardDeck>
			// 					</Row>
			// 				</Col>
			// 			</Row>
			// 			<Row className="bannerRow">
			// 				<Col md={{ span: 8, offset: 2 }}>
			// 					<h2>Are You a Developer?</h2>
			// 					<p>We are always looking for more developers to join Yarns library of available professionals. But it's not easy to get on Yarns approved list. We only work with the best and brightest.</p>
			// 					<a href="#">Find out More >></a>
			// 				</Col>
			// 			</Row>
			// 			<Row className="signupContainer">
			// 				<Row className="signupRow">
			// 					<Col md={{ span: 5, offset: 1 }}>
			// 						<p>Enter your email address and be among the first to gain access to Yarns library of developers via the Yarn early access program.</p>
			// 						<p class="disclaimer">Your email address will never be shared or spammed.</p>
			// 					</Col>
			// 					<Col md={{ span: 3, offset: 1 }}>
			// 						<Form id="form2" action="https://gmail.us20.list-manage.com/subscribe/post?u=f4af0d3153dc2ddcaa36e1238&amp;id=92ccab4ce2">
			// 							<Form.Group controlId="earlyAccess">
			// 								<Form.Control name="EMAIL" id="mc-EMAIL" type="email" placeholder="Email" className="center-text" required  />
			// 							</Form.Group>
			// 							<Button variant="primary" type="submit">
			// 								Let me know
			// 							</Button>
			// 						</Form>
			// 					</Col>
			// 				</Row>
			// 			</Row>
			// 			<Row className="footerRow">
			// 				<Col md={{ span: 2, offset: 3 }}>
			// 					<ul>
			// 						<li>Item One</li>
			// 						<li>Item Two</li>
			// 						<li>Item Three</li>
			// 					</ul>
			// 				</Col>
			// 				<Col md="2">
			// 					<ul>
			// 						<li>Item One</li>
			// 						<li>Item Two</li>
			// 						<li>Item Three</li>
			// 					</ul>
			// 				</Col>
			// 				<Col md="2">
			// 					<ul>
			// 						<li>Item One</li>
			// 						<li>Item Two</li>
			// 						<li>Item Three</li>
			// 					</ul>
			// 				</Col>
			// 			</Row>
			// 		</Container>
			// 	</header>
			// </div>
		);
	}
}

export default App;
