import React, {Component} from 'react';
import { Row, Col } from 'react-bootstrap';
export default class infoBoxes extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const element = (
            <div>
                <Row>
                    <Col>
                        <div class="infoBox">
                            <h1>
                                {/* <div class="titlecontainer">
                                    <div class="titlestring">
                                        <div class="titleinner">
                                            YARN
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div class="typewriter">
                                    <h1>Modern technical development resourcing for agile teams</h1>
                                </div> */}
                                {/* Modern technical development resourcing for agile teams */}
                                Next gen dynamic development resourcing for modern agile teams
                                {/* restack provides scalable and flexible access to highly skilled development resources that integrate deeply with your existing technical teams, solving issues with inconsistent amounts of work and tight deadlines or allowing you to keep growing during periods of staff shortages, turnover, hiring delays etc. */}
                                    {/* <span class="slidingVertical">
                                        <span>agile teams</span>
                                        <span>development managers</span>
                                        <span>hiring problems</span>
                                        <span>growth targets</span>
                                    </span> */}
                                {/* Next gen dynamic development resourcing for modern agile teams */}
                                {/* Modern Development Resourcing */}
                            </h1>
                            {/* <p class="lead">Yarn is a new service that offers bespoke access to a a pre-vetted library of skilled programmers.   Built and run by development managers to solve the real challenges and problems faced by development teams. With Yarn, you will always speak to someone with technical knowledge and not a recruitment middleman.</p>
                            <p>Yarn helps you to:</p>
                            <Row>
                                <Col sm="6" className="bulletCol">
                                    <ul>
                                        <li>Quickly get the best temporary staff into your team to help with big projects</li>
                                        <li>Take on new work before the hiring process is complete</li>
                                        <li>Cover missing skill sets</li>
                                    </ul>
                                </Col>
                                <Col sm="6" className="bulletCol">
                                    <ul>
                                        <li>Keep project deadlines on track</li>
                                        <li>Cut out the time and risk from using freelance websites</li>
                                        <li>Help with the workload during periods of staff illness or leave</li>
                                    </ul>
                                </Col>
                            </Row> */}
                        </div>
                    </Col>
                </Row>
            </div>
        )
        return (<div className="comptext">
            {element}
        </div>)
    }
}